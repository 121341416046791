<template>
  <div class="aboutus">
    <div class="bannerA">
      <img class="banimg" src="@/assets/images/join_about/join_about.png" />
    </div>
    <div class="bannerB">
      <div id="banner-top">
        <div class="header-box">招聘岗位信息</div>
      </div>
      <div class="banner-information">
        <div
          class="banner-details"
          v-for="(item, index) in details"
          :key="index"
        >
          <h3>
            {{ item.title }}
            <span @click="comprehend(item.id)">了解详情>></span>
          </h3>
          <h4>
            {{ item.section }}
          </h4>
          <div>{{ item.site }}</div>
        </div>
      </div>
    </div>
    <div class="Fooder">
      <h2>欢迎加入一家勇于创新的公司</h2>
      <h3>
        <img
          src="@/assets/images/join_about/icons-fooder.png"
          alt=""
        />邮箱：writemall@126.com
      </h3>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
    <el-dialog
      title="岗位须知"
      :modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <h1>深圳写手智能科技有限公司</h1>

      <div v-for="(it, idx) in aboutus" :key="idx">
        <div v-if="it.id == id">
          <p>
            {{ it.text }}
          </p>
          <h2>{{ it.title }}</h2>
          <h4 v-for="(it1, idx) in it.th" :key="idx">
            {{ it1.tx }}
          </h4>
          <h2>{{ it.title1 }}</h2>
          <h4 v-for="it2 in it.ts" :key="it2.td">
            {{ it2.td }}
          </h4>
          <h2>{{ it.title2 }}</h2>
          <h4 v-for="it3 in it.tr" :key="it3.tr">
            {{ it3.tr }}
          </h4>
          <h2>四、报名与人选确定方式</h2>
          <h4>
            报名时间自公告发布之日起至有合适人选为止。请应聘者将简历发送至416179686@QQ.com。合则约见面试，不合我们将及时反馈信息。面试合格，进入试用期。应聘者应提供真实的信息资料（如学历学信网证明），凡弄虚作假者，一经查实，立即取消面试及聘用资格。
          </h4>
        </div>
      </div>
      <h2 class="company">深圳写手智能科技有限公司</h2>
      <h4>2022年5月17日</h4>
      <h2 class="company">联系人：颜先生</h2>
      <h2>联系电话：<span>13828584514</span></h2>
    </el-dialog>
  </div>
</template>

<script>
import commonFooter from "components/footer/commonFooter.vue";
export default {
  name: "introduce",
  components: {
    commonFooter,
  },
  data() {
    return {
      dialogVisible: false,
      id: 0,
      details: [
        {
          id: 0,
          title: "爬虫工程师",
          section: "采集部",
          site: "广东·深圳",
        },
        {
          id: 1,
          title: "数据标准化文员",
          section: "标椎化部",
          site: "广东·深圳",
        },
        {
          id: 2,
          title: "数据规划与管理",
          section: "标椎化部",
          site: "广东·深圳",
        },
        {
          id: 3,
          title: "营销员",
          section: "营销部",
          site: "广东·深圳",
        },
      ],
      aboutus: [
        {
          id: 0,
          title: "一、招聘条件",
          title1: "二、岗位、职级、职责",
          title2: "三、薪酬待遇、职级晋升",

          text: " 深圳写手智能科技有限公司（以下简称公司），是深圳前海极简信息咨询服务有限公司设立的全资子公司，致力于推动大数据产业的智能化发展，以AI、RPA等技术应用为基础，为人们解决实际问题提供美好体验。现公开招聘爬虫工程师3-5名，要求如下：",
          th: [
            {
              tx: "1.计算机、大数据、人工智能等相关专业毕业，代码功底扎实，专科毕业以上。",
            },
            {
              tx: "2.熟练掌握python语言，有良好的代码风格及编程能力。了解常见反爬虫机制，熟悉HTTP协议，会使用Fiddler等抓包工具。熟悉爬虫框架scrapy/pyspider者优先，有海量数据爬取经验者优先。",
            },
            {
              tx: "3.至少熟悉MongoDB/MySql/Redis其中一种数据库的使用和常规优化。",
            },
            {
              tx: "4.富有创新、团结协作精神，具有良好的沟通能力，分析解决问题能力和团队协作精神。具备较强的抗压能力、学习能力，对待工作有强烈的责任感。",
            },
          ],
          ts: [
            {
              td: "岗位名称：爬虫工程师",
            },
            {
              td: "岗位职级：P1技术员（专科、本科）、P2初级工程师（硕士）。",
            },
            {
              td: "岗位职责：软件开发。网页采集",
            },
          ],
          tr: [
            {
              tr: "（一）现金薪酬、社保与“五险一金”、福利待遇。",
            },
            {
              tr: "1.现金薪酬。由基本工资、月度绩效奖金、年终奖构成。分别是：（1）基本工资。面谈。表现优秀者，缩短试用期。（2）年终奖。固定奖励第13个月工资，浮动奖励根据年度考评结果确定。",
            },
            {
              tr: "2.社保与“五险一金”。按规定缴纳社保、“五险一金”，缴费基数为按当地政府执行标准购买，试用期不满一个月的依法不缴纳。",
            },
            {
              tr: "3.福利待遇。公司元旦、春节、三八、端午、劳动节、中秋、国庆、冬至等八大节日补贴。年度体检，定期组织公司团建活动。",
            },
            {
              tr: "（二）成长空间",
            },
            {
              tr: "公司职级分为管理、技术、事务三类。管理类10级别、技术类12个级别、事务类10个级别，可根据个人能力转岗。",
            },
            {
              tr: "1.固定晋升。同一职级可获得的薪酬点上限为50个，达到薪酬点上限，考核合格即可晋升职级。",
            },
            {
              tr: "2.提拔晋升。根据对公司贡献、个人能力提拔，不受时限、薪酬点限制。",
            },
            {
              tr: "3.岗位职级管理类M7以上、技术类P7以上、事务类S7以上，通过公司相关程序，进入公司合伙人体系。",
            },
          ],
        },
        {
          id: 1,
          title: "一、招聘条件",
          title1: "二、岗位、职级、职责",
          title2: "三、薪酬待遇、职级晋升",

          text: " 深圳写手智能科技有限公司（以下简称公司），是深圳前海极简信息咨询服务有限公司设立的全资子公司，致力于推动大数据产业的智能化发展，以AI、RPA等技术应用为基础，为人们解决实际问题提供美好体验。现公开招聘5-10名优秀专业人才，要求如下：",
          th: [
            {
              tx: "1.全日制专科毕业以上（应届毕业优先）。",
            },
            {
              tx: "2.富有创新、团结协作精神，28岁以下。",
            },
            {
              tx: "3.有互联网产品思维，具有较强的综合文字能力、沟通协调能力，能吃苦耐劳，能承受一定工作压力，可接受加班。",
            },
          ],
          ts: [
            {
              td: "岗位名称：数据标准化文员",
            },

            {
              td: "岗位职责：1.互联网信息分类与整合；2.书刊扫描、OCR识别、校对与整理；3.数据挖掘与分析；4.完成公司交办的其他工作任务。",
            },
          ],
          tr: [
            {
              tr: "（一）现金薪酬、社保与“五险一金”、福利待遇。",
            },
            {
              tr: "1.现金薪酬。由基本工资、月度绩效奖金、年终奖构成。分别是：（1）基本工资：3k-5K月；表现优秀者，缩短试用期。（2）月度绩效奖金。月度考核结果为优秀，基本工资上调1个薪酬点，当月生效覆盖全年。（3）年终奖。固定奖励第13个月工资，浮动奖励根据年度考评结果确定。",
            },
            {
              tr: "2.社保与“五险一金”。按规定缴纳社保、“五险一金”，缴费基数为上述第一项的正式工资，试用期依法不缴纳。",
            },
            {
              tr: "3.福利待遇：元旦、春节、三八、端午、劳动节、中秋、国庆、冬至等八大节日补贴。定期组织公司团建活动。",
            },
            {
              tr: "（二）成长空间",
            },
            {
              tr: "公司职级分为管理、技术、事务三类。管理类10级别、技术类12个级别、事务类10个级别，可根据个人能力转岗。",
            },
            {
              tr: "1.固定晋升。同一职级可获得的薪酬点上限为50个，达到薪酬点上限，考核合格即可晋升职级。",
            },
            {
              tr: "2.提拔晋升。根据对公司贡献、个人能力提拔，不受时限、薪酬点限制。",
            },
            {
              tr: "3.岗位职级管理类M7以上、技术类P7以上、事务类S7以上，通过公司相关程序，进入公司合伙人体系。",
            },
          ],
        },
        {
          id: 2,
          title: "一、招聘条件",
          title1: "二、岗位、职级、职责",
          title2: "三、薪酬待遇、职级晋升",

          text: " 深圳写手智能科技有限公司（以下简称公司），是深圳前海极简信息咨询服务有限公司设立的全资子公司，致力于推动大数据产业的智能化发展，以AI、RPA等技术应用为基础，为人们解决实际问题提供美好体验。现公开招聘1-2名优秀数据规划与管理专业人才，要求如下：",
          th: [
            {
              tx: "1.全日制专科以上、档案学、情报学、文献学和目录学毕业（应届毕业优先）。",
            },
            {
              tx: "2.富有创新、团结协作精神，28岁以下。",
            },
            {
              tx: "3.有互联网产品思维，具有较强的综合文字能力、沟通协调能力，能吃苦耐劳，能承受一定工作压力，可接受加班。",
            },
          ],
          ts: [
            {
              td: "岗位名称：数据规划与管理",
            },

            {
              td: "岗位职责：1.互联网图书信息分类与整合；2.书刊扫描、OCR识别、校对与整理；3.数据挖掘与分析；4.完成公司交办的其他工作任务。",
            },
          ],
          tr: [
            {
              tr: "（一）现金薪酬、社保与“五险一金”、福利待遇。",
            },
            {
              tr: "1.现金薪酬。由基本工资、月度绩效奖金、年终奖构成。分别是：（1）基本工资：3k-5K月；表现优秀者，缩短试用期。（2）月度绩效奖金。月度考核结果为优秀，基本工资上调1个薪酬点，当月生效覆盖全年。（3）年终奖。固定奖励第13个月工资，浮动奖励根据年度考评结果确定。",
            },
            {
              tr: "2.社保与“五险一金”。按规定缴纳社保、“五险一金”，缴费基数为上述第一项的正式工资，试用期依法不缴纳。",
            },
            {
              tr: "3.福利待遇：元旦、春节、三八、端午、劳动节、中秋、国庆、冬至等八大节日补贴。定期组织公司团建活动。",
            },
            {
              tr: "（二）成长空间",
            },
            {
              tr: "公司职级分为管理、技术、事务三类。管理类10级别、技术类12个级别、事务类10个级别，可根据个人能力转岗。",
            },
            {
              tr: "1.固定晋升。同一职级可获得的薪酬点上限为50个，达到薪酬点上限，考核合格即可晋升职级。",
            },
            {
              tr: "2.提拔晋升。根据对公司贡献、个人能力提拔，不受时限、薪酬点限制。",
            },
            {
              tr: "3.岗位职级管理类M7以上、技术类P7以上、事务类S7以上，通过公司相关程序，进入公司合伙人体系。",
            },
          ],
        },
        {
          id: 3,
          title: "一、招聘条件",
          title1: "二、岗位、职级、职责",
          title2: "三、薪酬待遇",

          text: " 深圳写手智能科技有限公司（以下简称公司），是深圳前海极简信息咨询服务有限公司设立的全资子公司，致力于推动现代信息咨询服务业的智能化发展，以AI、RPA等技术应用为基础，为人们解决实际问题提供美好体验。现公开招聘1-3名营销类优秀人才。要求如下：",
          th: [
            {
              tx: "1.市场营销相关专业毕业，专科或以上学历；",
            },
            {
              tx: "2.3年以上计算机软件行业销售经历或3年以上线上产品销售和渠道销售经验，业绩突出者优先；",
            },
            {
              tx: "3.性格外向、反应敏捷、表达能力强，具有较强的沟通能力及交际技巧，具有亲和力；",
            },
            {
              tx: "4.有敏锐的市场意识、应变能力、良好的客户服务意识和独立开拓市场的能力，",
            },
            {
              tx: "5.具有强烈的进取心，精力充沛，身体健康，乐观豁达，富有开拓精神；",
            },
            {
              tx: "6.有责任心，能承受较大的工作压力；有强烈的赚钱欲望，忠于销售工作，愿意与企业共谋发展。",
            },
          ],
          ts: [
            {
              td: "岗位名称：营销员",
            },

            {
              td: "岗位职责：1.负责公司产品的销售及推广；根据公司销售目标，制定营销计划并分解销售目标； 2.提供市场趋势、需求变化和客户反馈方面的准确信息；3.负责组建网络销售队伍及业务开展工作；4.负责营销总部的销售政策的落实、各项制度的贯彻执行；5.直接参与主持重要客户的业务谈判及合同签订；6.负责销售区域内销售活动的策划和执行，完成销售任务。7.制定销售计划，开拓新市场,拓展新客户,并将完成情况并随时向销售总监汇报；8.完成直接上级交办的其他的各项任务。",
            },
          ],
          tr: [
            {
              tr: "1.月基本薪资底薪+高业绩提成，保底薪资6K起；享受13薪；试用期通过考核的，签署1-5年期劳动合同。",
            },
            {
              tr: "2.正式录用。总薪酬由基本工资+业绩提成+绩效奖金+年终奖构成，绩效奖金、年终奖视岗位KPI考核目标完成情况确定。",
            },
            {
              tr: "3.按当地政府规定缴纳社保、“五险一金”。",
            },
            {
              tr: "4.福利待遇：元旦、春节、三八、端午、劳动节、中秋、国庆、冬至等八大节日补贴。定期组织公司团建活动。",
            },
          ],
        },
      ],
    };
  },
  methods: {
    comprehend(id) {
      this.id = id;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #f0f2f5;
}
.aboutus {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #f5f5f2;
  .bannerA {
    width: 100%;
    height: 420px;
    position: relative;
    margin-top: -30px;
    margin-left: -1px;

    .banimg {
      width: 100%;
      height: 100%;
    }
  }
  .bannerB {
    // margin-top: 100px;
    // height: 100%;
    width: 1526px;
    text-align: center;
    margin: 0 auto;
    margin-top: -30px;
    background: #fff;
    position: relative;
    z-index: 99;
    padding-top: 30px;
    border-radius: 8px;
    #banner-top {
      padding: 0 199px;

      box-sizing: border-box;
      .header-box {
        width: 100%;
        height: 44px;
        font-size: 38px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
    }
    .banner-information {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      padding: 84px 214px 145px;
      box-sizing: border-box;

      .banner-details {
        width: 468px;
        height: 225px;
        // margin-right: 40px;
        margin-bottom: 26px;
        padding: 48px 37px 39px 39px;
        box-sizing: border-box;
        background: #fdfdfd;
        box-shadow: 0px 5px 20px 1px rgba(75, 86, 98, 0.13);
        border-radius: 10px;
        text-align: left;
        h3 {
          width: 100%;
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          span {
            cursor: pointer;
            float: right;
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ff6900;
          }
        }
        h4 {
          width: 100%;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-top: 31px;
        }
        div {
          text-align: right;
          width: 100%;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          margin-top: 44px;
        }
      }
      :nth-child(3n) {
        margin: 0;
      }
    }
  }
  .Fooder {
    width: 1526px;
    background: #fff;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 167px;
    padding-bottom: 60px;
    h2 {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #000000;
    }
    h3 {
      margin-top: 52px;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      img {
        width: 26px;
        height: 20px;
        vertical-align: middle;
        margin-right: 20px;
      }
    }
  }
  ::v-deep .el-dialog {
    width: 650px;
    height: 445px;
    background: #ffffff;
    border: 0px solid #818790;
    box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

    .el-dialog__header {
      padding: 16px 12px;
      .el-dialog__title {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #393939;
      }
    }
    .el-dialog__body {
      padding: 20px 40px;
      overflow-y: auto;
      max-height: 335px;
      h1 {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        opacity: 0.9;
        margin-bottom: 23px;
      }
      p {
        font-size: 14px;
        color: #333;
        line-height: 26px;
        margin-bottom: 12px;
      }
      h2 {
        font-size: 16px;
        color: #333;
        margin: 18px 0px;
        span {
          color: #ff6900;
        }
      }
      .company {
        margin-top: 40px;
        margin-bottom: 10px;
      }
      h4 {
        font-weight: 400;
        font-size: 14px;
        color: #333;
        line-height: 30px;
      }
    }
  }
}
</style>
<style>
/*滚动条bai整体*/
::-webkit-scrollbar {
  width: 2px; /*滚动条宽度*/
}
::-webkit-scrollbar-track {
  background-color: #fff; /*滑道全部*/
}
::-webkit-scrollbar-track-piece {
  background-color: #fff; /*滑道*/
}
::-webkit-scrollbar-thumb {
  background-color: #fafafa; /*滑动条表面*/
  border: none; /*滑动条边框*/
}
</style>
